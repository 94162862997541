import React from 'react';
import * as Router from "react-router-dom";
import './Matchup.scss';
import TrackDisplay from './TrackDisplay';
import Logic, { MatchupResult, WeightedScores } from './logic/matchup';
import { Track, thumbnailURL, officialList, spotifyURL, previewURL } from './logic/core';
import Button from 'react-bootstrap/Button';
import AudioPlayer from './logic/audioplayer';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DEFAULT_THRESHOLD = 5;
const THRESHOLD_KEY = 'matchup_threshold';

interface MatchupProps {}

interface MatchupState {
  shortlistTooShort: boolean,
  leftTrack?: Track,
  rightTrack?: Track,
  currentTop10: [Track, WeightedScores][],
  history: Logic['history'],
  threshold:  number
}

class Matchup extends React.Component<MatchupProps, MatchupState> {

  private logic = new Logic();

  private leftRef: React.RefObject<TrackDisplay>;
  private rightRef: React.RefObject<TrackDisplay>;

  handleAppStateChanged: () => Promise<void>

  constructor(props: MatchupProps) {
    super(props);

    const threshold: number = parseFloat(localStorage.getItem(THRESHOLD_KEY) ?? DEFAULT_THRESHOLD.toString());
    this.state = {
      shortlistTooShort: true,
      currentTop10: [],
      history: [],
      threshold: threshold
    }
    this.handleAppStateChanged = async () => {
      await this.updateMatchup();
    };

    this.leftRef = React.createRef();
    this.rightRef = React.createRef();
  }

  async componentDidMount() {
    await this.logic.removeTracksNotInShortlist();
    await this.updateMatchup();
    document.addEventListener('app:state-changed', this.handleAppStateChanged);
  }

  componentWillUnmount() {
    document.removeEventListener('app:state-changed', this.handleAppStateChanged);
  }

  async updateMatchup() {
    const shortlistCount = await this.logic.shortlistCount();
    if (shortlistCount <= 10) {
      this.setState({
        shortlistTooShort: true
      });
      return;
    } else {
      this.setState({
        shortlistTooShort: false
      });
    }

    const manualThresh = localStorage.getItem(THRESHOLD_KEY);
    if (manualThresh === null) {
      const autoThreshold = Math.floor(Math.log(shortlistCount));
      this.setState({
        threshold: autoThreshold
      });
    }

    let currentMatchup = await this.logic.getCurrent();
    if (!currentMatchup) {
      // Try it again - forces a new round if needed
      currentMatchup = await this.logic.getCurrent();
    }


    if (currentMatchup) {
      this.setState({
        leftTrack: officialList[currentMatchup.left],
        rightTrack: officialList[currentMatchup.right]
      });
    } else {
      this.setState({
        leftTrack: undefined,
        rightTrack: undefined
      });
    }

    this.leftRef.current?.blur();
    this.rightRef.current?.blur();

    const top10 = await this.logic.getTop10(this.state.threshold);
    this.setState({
      currentTop10: top10,
      history: this.logic.history.slice(-20).reverse()
    });
  }

  renderTrackDisplay(track: Track, allowReject: boolean, ref?: React.RefObject<TrackDisplay>) {
    return <TrackDisplay
      artist={track.artist_name}
      track={track.title}
      albumArt={thumbnailURL(track)}
      playbackUrl={previewURL(track)}
      spotifyUrl={spotifyURL(track)}
      onDelete={allowReject ? (() => {this.reject(track)}) : undefined}
      key={track.id}
      ref={ref} />
  }

  async resolve(resolution: MatchupResult['resolution']) {
    AudioPlayer.shared.pause();

    if (this.state.leftTrack && this.state.rightTrack) {
      await this.logic.resolve({
        left: this.state.leftTrack.id,
        right: this.state.rightTrack.id,
        resolution: resolution
      });
    }
    await this.updateMatchup();
  }

  async reject(track: Track) {
    if (this.state.leftTrack && this.state.rightTrack) {
      if (this.state.leftTrack.id === track.id) {
        await this.resolve('reject-left');
      } else if (this.state.rightTrack.id === track.id) {
        await this.resolve('reject-right');
      } else {
        await this.logic.reject(track.id);
        await this.resolve('skip');
      }
    } else {
      await this.logic.reject(track.id);
      await this.resolve('skip');
    }
  }

  async decide(side: 'left' | 'right') {
    await this.resolve(side);
  }

  async skip() {
    await this.resolve('skip');
  }

  renderTop10() {
    const trax = this.state.currentTop10.map(([track, weights]) => {
      const overallWeight = (weights.winWeight - weights.lossWeight);
      const className = (overallWeight > 0) ? "win-weight" : "loss-weight";
      const absWeight = Math.abs(overallWeight);
      let logWeight = Math.log1p(absWeight) / Math.log1p(Math.abs(this.state.threshold));
      if (Math.abs(this.state.threshold) < 1) {
        logWeight = Math.log1p(absWeight);
      }
      console.log("log weight", absWeight, logWeight);
      return (<div className="top-10-track" key={track.id}>
        {this.renderTrackDisplay(track, false)}
        <div className="track-confidence">
          <span className={className} style={{backgroundSize: Math.min(100, (logWeight * 10)) + "% 100%"}}>{(weights.winWeight - weights.lossWeight).toFixed(2)}</span>
        </div>
      </div>)
    });
    while(trax.length < 10) {
      trax.push(<div className="dummy-track" key={'dummy-'+trax.length}>???</div>);
    }

    if (trax.length > 10) {
      trax.splice(10, 0, <hr key="--separator" />);
    }

    return <div className="Matchup-top-10">
    {trax}
    </div>
  }

  async undo(result: MatchupResult) {
    await this.logic.undo(result);
    await this.updateMatchup();
  }

  async updateThreshold(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const newThreshold = parseFloat(event.target.value);

    localStorage.setItem(THRESHOLD_KEY, newThreshold.toString());

    const top10 = await this.logic.getTop10(newThreshold);
    this.setState({
      threshold: newThreshold,
      currentTop10: top10
    });
  }

  renderHistory() {
    return this.state.history.map((o, i) => <HistoryEntry result={o} key={o.left + ':' + o.right} onUndo={() => this.undo(o)} />);
  }

  render () {
    if (this.state.shortlistTooShort) {
      return (
        <div className="Matchup">
          <p className="lead text-center">You need more than ten songs in your shortlist to get started!</p>
        </div>
        );
    }
    return (
      <div className="Matchup">
        <p className="lead text-center">Pick the best song:</p>
        <div className="side-by-side mb-2">
          <div className="sbs-left" onPointerUp={() => this.decide('left')}>
            {this.state.leftTrack && this.renderTrackDisplay(this.state.leftTrack, true, this.leftRef)}
          </div>
          <div className="sbs-right" onPointerUp={() => this.decide('right')}>
            {this.state.rightTrack && this.renderTrackDisplay(this.state.rightTrack, true, this.rightRef)}
          </div>
        </div>
        <div className="d-grid gap-2">
          <Button variant="secondary" size="lg" onPointerUp={() => this.skip()}>Not sure</Button>
        </div>

        <hr />

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="clearfix mb-2 d-flex align-items-center">
                <h4 className="flex-grow-1">Your top 10</h4>
                <label htmlFor="Matchup-threshold">Threshold&nbsp;</label>
                <Form.Control type="number" value={this.state.threshold} style={{maxWidth: "5em"}} onChange={(evt) => this.updateThreshold(evt)} />
              </div>
              {this.renderTop10()}
            </div>
            <div className="col-md-6">
              <h4 className="text-center">History</h4>
              {this.renderHistory()}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

interface HistoryEntryProps {
  result: MatchupResult,
  onUndo?: () => void
}

class HistoryEntry extends React.Component<HistoryEntryProps> {
  constructor(props: HistoryEntryProps) {
    super(props);
  };

  renderResult() {
    const left = officialList[this.props.result.left];
    const right = officialList[this.props.result.right];
    const resolution = this.props.result.resolution;
    if (resolution === 'left') {
      return (<span>
          <strong>{left.artist_name} – {left.title}</strong> <small>is better than</small> <strong>{right.artist_name} – {right.title}</strong>
        </span>);
    } else if (resolution === 'right') {
      return (<span>
          <strong>{right.artist_name} – {right.title}</strong> <small>is better than</small> <strong>{left.artist_name} – {left.title}</strong>
        </span>);
    } else if (resolution === 'reject-left') {
      return (<span>Removed <strong>{left.artist_name} – {left.title}</strong> from shortlist</span>);
    } else if (resolution === 'reject-right') {
      return (<span>Removed <strong>{right.artist_name} – {right.title}</strong> from shortlist</span>);
    } else {
      return <span>Skipped <strong>{right.artist_name} – {right.title}</strong> <small>vs.</small> <strong>{left.artist_name} – {left.title}</strong></span>
    }
  }

  render() {
    return (
<div className="HistoryEntry">
{this.renderResult()} <span className="HistoryEntry-undo-button" role="button" aria-label="Undo" onClick={this.props.onUndo} ></span>
</div>
    );
  }
}

export default Matchup;

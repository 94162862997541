import React from 'react';
import './TrackDisplay.scss';
import AudioPlayer from './logic/audioplayer';
import SwipeToDelete from './SwipeToDelete';

const PLAY_BUTTON      = <img src={process.env.PUBLIC_URL + "img/play-circle-fill.svg"} alt="Play" title="Play" />
const PAUSE_BUTTON     = <img src={process.env.PUBLIC_URL + "img/pause-circle-fill.svg"} alt="Pause" title="Pause" />
const SPOTIFY_BUTTON   = <img src={process.env.PUBLIC_URL + "img/spotify-logo.svg"} alt="Open in Spotify" title="Open in Spotify" />

interface TrackDisplayProps {
  albumArt?: string;
  artist: string;
  track: string;
  playbackUrl?: string;
  spotifyUrl?: string;
  onDelete?: () => void;
  autoplay?: boolean
}

interface TrackDisplayState {
  isPlaying: boolean;
  loadedPercent: number | null;
}

export default class TrackDisplay extends React.Component<TrackDisplayProps, TrackDisplayState> {

  private _audio = AudioPlayer.shared;

  private _ref: React.RefObject<HTMLDivElement>;

  private _onUnmount: Array<() => void>;

  constructor(props: TrackDisplayProps) {
    super(props);
    this.state = {
      isPlaying: false,
      loadedPercent: 100
    }

    this._onUnmount = [];

    this._ref = React.createRef();
  }

  componentDidMount() {
    const _addAudioEvent = (event: string, handler: EventListenerOrEventListenerObject) => {
      this._audio.addEventListener(event, handler);
      this._onUnmount.push(() => this._audio.removeEventListener(event, handler));
    };

    _addAudioEvent('ended', (evt) => this.audioEndedHandler(evt));
    _addAudioEvent('pause', (evt) => this.audioPauseHandler(evt));
    _addAudioEvent('play', (evt) => this.audioPlayHandler(evt));
    _addAudioEvent('progress', (evt) => this.audioProgressHandler(evt));

    _addAudioEvent('loadstart', (evt) => {
      this.setState({ loadedPercent: null });
    });
  }

  componentWillUnmount() {
    if (this.state.isPlaying) {
      this._audio.pause();
    }
    for(let finalise of this._onUnmount) {
      finalise();
    }
  }

  componentDidUpdate(prevProps: TrackDisplayProps) {
    if ((prevProps.playbackUrl !== this.props.playbackUrl) &&
      this.state.isPlaying) {
      if (this.props.autoplay) {
        this._audio.play(this.props.playbackUrl ?? '');
      } else {
        this.setState({
          isPlaying: false
        });
      }
    }
  }

  audioEndedHandler(evt: Event) {
    if ((evt instanceof CustomEvent) && (evt.detail.src === this.props.playbackUrl)) {
      this.setState({
        isPlaying: false
      });
    }
  }

  audioPauseHandler(evt: Event) {
    // Doesn't matter where this came from, every trackDisplay needs to pause
    this.setState({
      isPlaying: false
    });
  }

  audioPlayHandler(evt: Event) {
    if ((evt instanceof CustomEvent) && (evt.detail.src === this.props.playbackUrl)) {
      this.setState({
        isPlaying: true
      });
    }
  }

  audioProgressHandler(evt: Event) {
    if ((evt instanceof CustomEvent) && (evt.detail.src === this.props.playbackUrl)) {
      console.log(this._audio.loaded);
      
      this.setState({
        loadedPercent: this._audio.loaded
      });
    }
  }

  blur() {
    this._ref?.current?.blur();
  }

  render() {
    let audioButton: React.ReactNode = '';
    if (this.props.playbackUrl) {
      const buttonArt = this.state.isPlaying ? PAUSE_BUTTON : PLAY_BUTTON;
      const loadedPercent = this.state.loadedPercent ?? 1;
      let extraClass = '';
      if (this.state.isPlaying && this.state.loadedPercent === null)
        extraClass = 'pulsing';
      else if (loadedPercent < 0.999)
        extraClass = 'loading';
      audioButton = <div className={`TrackDisplay-external-player ${extraClass}`} onPointerUp={(evt) => this._playPauseClick(evt)} style={{'--completion': `${loadedPercent * 100}%`} as React.CSSProperties}>{buttonArt}</div>;
    }

    let spotifyButton: React.ReactNode = '';
    if (this.props.spotifyUrl) {
      spotifyButton = <a className="TrackDisplay-external-player" href={this.props.spotifyUrl} target="_blank" rel="noreferrer" onPointerUp={(evt) => evt.stopPropagation()}>
        {SPOTIFY_BUTTON}
      </a>;
    }

    let albumArt: React.ReactNode = '';
    if (this.props.albumArt) {
      albumArt = <img src={this.props.albumArt} alt="" />;
    }

    const container = <div className="TrackDisplay-container">
            <div className="TrackDisplay-picture">
              {albumArt}
            </div>
            <div className="TrackDisplay-details">
              <div className="TrackDisplay-artist">{this.props.artist}</div>
              <div className="TrackDisplay-track">{this.props.track}</div>
            </div>
            <div className="TrackDisplay-links" onPointerDown={(evt) => evt.stopPropagation()}>
              {audioButton}
              {spotifyButton}
            </div>
          </div>

    if (this.props.onDelete) {
      let deleteButton = <div className="TrackDisplay-delete-button" aria-label="Delete" onPointerUp={(evt) => this._deleteClick(evt)}></div>
      return (
        <div className="TrackDisplay-wrapper" ref={this._ref}>
          <SwipeToDelete onDelete={this.props.onDelete} deleteText="Remove from shortlist">
            {container}
          </SwipeToDelete>
          {deleteButton}
        </div>
      );
    } else {
      return (<div className="TrackDisplay-wrapper" ref={this._ref}>
        {container}
      </div>);
    }

  }

  _playPauseClick(evt: React.SyntheticEvent<any, PointerEvent>) {
    evt.stopPropagation();
    let isPlaying = this.state.isPlaying;

    if (isPlaying) {
      this._audio.pause();
      isPlaying = false;
    } else if (this.props.playbackUrl) {
      this._audio.play(this.props.playbackUrl);
      isPlaying = true;
    }

    this.setState({ isPlaying });
  }

  _deleteClick(evt: React.SyntheticEvent<any, PointerEvent>) {
    evt.stopPropagation();
    this.props.onDelete?.();
  }

}



const forwardedEvents = ['ended', 'pause', 'play', 'progress', 'loadstart'];

export const AUDIO_CACHE_SIZE = 6;

export default class AudioPlayer extends EventTarget {

  private _audio: HTMLAudioElement;
  private _cache: HTMLAudioElement[];

  private constructor() {
    super();

    this._cache = Array.from({length: AUDIO_CACHE_SIZE}, () => {
      const audio = new Audio();
      audio.preload = 'auto';
      audio.muted = true;
      return audio;
    });
    
    for(const audio of this._cache) {
      for (const eventName of forwardedEvents) {
        audio.addEventListener(eventName, this);
      }
    }

    this._audio = this._cache[0];
  }

  public handleEvent(event: Event) {
    if (event.target === this._audio) {
      this.dispatchEvent(new CustomEvent(event.type, {detail: {
        src: this._audio.src,
        originalEvent: event
      }}));
    }
  }

  public static shared = new AudioPlayer();

  public play(src: string) {
    if (this._audio.src !== src) {
      // Changing source, broadcast ended
      this.dispatchEvent(new CustomEvent('ended', {detail: {src: this._audio.src}}));
      this.load(src);
    }
    if (this._audio.paused) {
      this._audio.play();
    }
  }

  public pause() {
    this._audio.pause();
  }

  public get loaded(): number | null {
    if (isNaN(this._audio.duration)) {
      return null;
    }
    const buffered = this._audio.buffered;
    let total = 0;
    for(let i = 0; i < buffered.length; i++) {
      total += buffered.end(i) - buffered.start(i);
    }
    
    return total / this._audio.duration;
  }

  public precache(src: string): boolean {
    for(let player of this._cache) {
      if (player.src === src) {
        console.log(`${src} already cached`);
        return true;
      }
      if (!player.src) {
        console.log(`precaching ${src}`);
        player.src = src;
        return true;
      }
    }

    return false;
  }

  private getCached(src: string): HTMLAudioElement | undefined {
    for(let player of this._cache) {
      if (player.src === src)
        return player;
    }
  }

  private load(src: string) {
    let audio = this.getCached(src);
    // Make sure this audio is now available for caching
    this._audio.src = '';
    this._audio.muted = true;
    if (audio) {
      this._audio = audio;
    } else {
      this._audio.src = src;
    }
    this._audio.muted = false;
  }

}

import { officialList, Track, LongList, getDB, shuffleArray } from './core';

export default class LonglistLogic {

  public currentTrack?: Track;
  public currentTrackIndex: number = -1;

  public isComplete: boolean = false;

  async resetLonglist(defaultState: LongList['state'] = "unknown") {
    const db = await getDB();
    const longlist = await db.transaction('longlist', 'readwrite').store;

    await longlist.clear();

    let tracks = Object.values(officialList).filter(o => o.is_official === 1);
    shuffleArray(tracks);
    for (let i = tracks.length - 1; i > 0; i--) {
      await longlist.add({
        id: tracks[i].id,
        order: i,
        state: defaultState
      });
    }

    this.currentTrackIndex = -1;
  }

  async seedIfNeeded() {
    const db = await getDB();
    const longlist = await db.transaction('longlist', 'readwrite').store;

    const count = await longlist.count();
    if (count === 0) {
      await this.resetLonglist();
    }
  }

  async nextTrack(): Promise<Track | undefined> {
    const db = await getDB();
    const longlist = await db.transaction('longlist').store;

    const range = IDBKeyRange.lowerBound(this.currentTrackIndex, true);
    let cursor = await longlist.index('order').openCursor(range);
    while (cursor) {
      if (cursor.value.state === 'unknown') {
        this.currentTrackIndex = cursor.value.order;
        this.currentTrack = officialList[cursor.value.id];
        break;
      }
      cursor = await cursor.continue();
    }

    this.isComplete = !cursor;

    return this.currentTrack ?? undefined;
  }

  async getTrack(): Promise<Track | undefined> {
    const db = await getDB();

    const value = await db.getFromIndex('longlist', 'order', this.currentTrackIndex);
    if (value) {
      this.currentTrack = officialList[value.id];
    }

    return this.currentTrack ?? undefined;
  }

  async peekTracks(count: number): Promise<Track[]> {
    const db = await getDB();

    const longlist = await db.transaction('longlist').store;
    const range = IDBKeyRange.lowerBound(this.currentTrackIndex, true);
    let cursor = await longlist.index('order').openCursor(range);
    
    const tracks = [];
    for (; cursor && tracks.length < count; cursor = await cursor.continue()) {
      if (cursor.value.state === 'unknown') {
        tracks.push(officialList[cursor.value.id]);
      }
    }

    return tracks;
  }

  async updateState(track: Track, newState: LongList['state']) {
    const db = await getDB();
    const longlist = await db.transaction('longlist', 'readwrite').store;

    let trackState = await longlist.get(track.id);
    if(!trackState) {
      return;
    }

    trackState.state = newState;

    await longlist.put(trackState);

    if (newState === 'reject-all') {
      let currentArtistIds = new Set(track.artists.map(a => a.id));
      for(let trackId in officialList) {
        let track = officialList[trackId];
        if (track.artists.length === currentArtistIds.size) {
          // Are the artist IDs identical? Same size and each artist appears in both?
          let remove = track.artists.every(a => currentArtistIds.has(a.id));
          if (remove) {
            let trackState = await longlist.get(track.id);
            if (trackState) {
              trackState.state = newState;
              await longlist.put(trackState);
            }
          }
        }
      }
    }
  }

  async getShortlist(): Promise<Track[]> {
    const db = await getDB();
    const longlist = await db.transaction('longlist').store;

    const entries = await longlist.index('state').getAll('accept')
    const tracks = entries.map(e => officialList[e.id.toString()]);

    tracks.sort((a, b) => {
      if (a.artist_name.toUpperCase() < b.artist_name.toUpperCase()) {
        return -1;
      } else if (a.artist_name.toUpperCase() > b.artist_name.toUpperCase()) {
        return 1;
      }
      return 0;
    });

    return tracks;
  }

  async getStats() {
    const db = await getDB();
    const longlist = await db.transaction('longlist').store;

    const total = await longlist.count();
    const remaining = await longlist.index('state').count('unknown');
    const accepted = await longlist.index('state').count('accept');
    const done = total - remaining;

    return {done, total, accepted};
  }

}

import SpotifyAPI from 'spotify-web-api-js';
import { getDB, officialList, Track } from './core';

import LonglistLogic from './longlist';

const spotifyAPI = new SpotifyAPI();
const CLIENT_ID = 'df693d57bc374ec0a2b558650c56b324';

const ACCESS_TOKEN_KEY = 'spotify_access_token';
const PLAYLIST_ID_KEY = 'spotify_playlist_id_2024';
const STATE_KEY = '__temp_spotify_state';
const REDIRECT_KEY = '__temp_spotify_redirect';
const ACTION_KEY = '__temp_spotify_action';
const PARAMS_KEY = '__temp_spotify_params';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function randomString(): string {
  return btoa(Array.from(crypto.getRandomValues(new Uint8Array(9))).map( c => String.fromCharCode(c) ).join(''));
}

interface SpotifyActions {
  replaceShortlistWithPlaylist(playlistId: string): Promise<void>
  updatePlaylistFromShortlist(): Promise<void>
}

type SpotifyPasteboardData = {
  PLAYLIST_ID_KEY: string | null
}

class Spotify implements SpotifyActions {

  get isLoggedIn(): boolean {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken) {
      spotifyAPI.setAccessToken(accessToken);
      return true;
    }
    return false;
  }

  get isPlaylistCreated(): boolean {
    return localStorage.getItem(PLAYLIST_ID_KEY) !== null;
  }

  clearToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    spotifyAPI.setAccessToken(null);
  }

  copyData(): SpotifyPasteboardData {
    return {
      PLAYLIST_ID_KEY: localStorage.getItem(PLAYLIST_ID_KEY)
    }
  }

  pasteData(data: SpotifyPasteboardData) {
    if (data.PLAYLIST_ID_KEY) {
      localStorage.setItem(PLAYLIST_ID_KEY, data.PLAYLIST_ID_KEY);
    }
  }

  login() {
    const state = randomString();
    localStorage.setItem(STATE_KEY, state);

    let redirect = new URL('./spotify_redirect', BASE_URL);

    let url = new URL('https://accounts.spotify.com/authorize');
    url.searchParams.set('client_id', CLIENT_ID);
    url.searchParams.set('response_type', 'token');
    url.searchParams.set('redirect_uri', redirect.toString());
    url.searchParams.set('scope', 'playlist-modify-private');
    url.searchParams.set('state', state);

    window.location.href = url.toString();
  }

  async performAction<K extends keyof SpotifyActions>(action: K, ...params: Parameters<SpotifyActions[K]>) {
    if (this.isLoggedIn) {
      try {
        // @ts-ignore: 2684
        await this[action](...params);
        return;
      } catch (e) {
        if (e instanceof XMLHttpRequest) {
          if (e.status === 401) {
            this.clearToken();
          } else {
            const error = JSON.parse(e.responseText);
            throw error.error.message;
          }
        } else {
          throw e;
        }
      }

    }

    // Otherwise, do a login
    localStorage.setItem(REDIRECT_KEY, window.location.pathname);
    localStorage.setItem(ACTION_KEY, action);
    localStorage.setItem(PARAMS_KEY, JSON.stringify(params));

    this.login();
  }

  async handleRedirect(result: URLSearchParams) {
    const checkState = localStorage.getItem(STATE_KEY);

    const access_token = result.get('access_token');
    const state = result.get('state');
    const expires_in = result.get('expires_in');
    const error = result.get('error');

    if (state !== checkState) {
      // Wrong state, just return us to the top level
      console.debug('State was wrong, bail');
      return '/';
    }

    const redirect = localStorage.getItem(REDIRECT_KEY);
    const action = localStorage.getItem(ACTION_KEY);
    const params = JSON.parse(localStorage.getItem(PARAMS_KEY) ?? '[]');

    localStorage.removeItem(STATE_KEY);
    localStorage.removeItem(REDIRECT_KEY);
    localStorage.removeItem(ACTION_KEY);
    localStorage.removeItem(PARAMS_KEY);

    // TODO: check if anything else went wrong
    if (access_token) {
      localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
      spotifyAPI.setAccessToken(access_token);
      // Perform the action we had waiting

      // @ts-ignore: 2684
      await this[action](...params);

      return redirect;
    } else {
      throw error ?? 'unknown error';
    }
  }

  async replaceShortlistWithPlaylist(playlistId: string) {
    const longlistLogic = new LonglistLogic();

    let playlistTracks: string[] = [];
    while(true) {
      const response = await spotifyAPI.getPlaylistTracks(playlistId, {fields: 'total,items.track.id', limit: 50, offset: playlistTracks.length});
      playlistTracks = playlistTracks.concat(response.items.map(i => i.track.id));
      if (response.items.length === 0) {
        break;
      }
    }

    await longlistLogic.resetLonglist('reject');

    let officialListBySpotifyId = new Map<string, Track>(Object.values(officialList)
        .filter(t => t.spotify_track_id !== null)
        .map(t => [t.spotify_track_id!, t]));

    for(let spotifyId of playlistTracks) {
      const track = officialListBySpotifyId.get(spotifyId);
      if (track) {
        await longlistLogic.updateState(track, 'accept');
      }
    }
  }

  async updatePlaylistFromShortlist() {
    const db = await getDB();
    const longlist = await db.transaction('longlist').store;

    const entries = await longlist.index('state').getAll('accept');
    const tracks = entries.map(e => officialList[e.id.toString()]);
    tracks.sort((a, b) => a.artist_name < b.artist_name ? -1 : (a.artist_name > b.artist_name ? 1 : 0))
    const uris = tracks.filter(o => o.spotify_track_id != null)
      .map(o => `spotify:track:${o.spotify_track_id}`);

    let playlistId = localStorage.getItem(PLAYLIST_ID_KEY);

    if (!playlistId) {
      const user = await spotifyAPI.getMe();
      const playlist = await spotifyAPI.createPlaylist(user.id, {
        name: 'Hottest 100 Helper 2024',
        public: false
      });
      playlistId = playlist.id;
      localStorage.setItem(PLAYLIST_ID_KEY, playlistId);
    }

    // Chunk into 100 track size chunks
    await spotifyAPI.replaceTracksInPlaylist(playlistId, []);

    for(let i = 0; i < uris.length; i += 100) {
      let _uris = uris.slice(i, i + 100);
      await spotifyAPI.addTracksToPlaylist(playlistId, _uris);
    }

    let problemTracks = tracks
      .filter(o => o.spotify_track_id == null)
      .map(o => `${o.artist_name.toUpperCase()} – ${o.title}`)
      .join('\n');

    if (problemTracks !== '') {
      problemTracks = "\n\nCouldn't add these tracks:\n" + problemTracks;
    }

    alert(`Created playlist "Hottest 100 Helper" in Spotify${problemTracks}`);

  }
}

export default new Spotify();

import React from 'react';
import * as Router from "react-router-dom";
import './Longlist.scss';
import TrackDisplay from './TrackDisplay';
import Logic from './logic/longlist';
import AudioPlayer from './logic/audioplayer';
import { Track, thumbnailURL, previewURL, spotifyURL, LongList } from './logic/core';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Collapse from 'react-bootstrap/Collapse';

interface LonglistProps {}

interface LonglistState {
  currentTrack?: Track,
  stats?: {
    done: number,
    total: number,
    accepted: number
  }
}

export default class LonglistComponent extends React.Component<LonglistProps, LonglistState> {

  private logic = new Logic();

  constructor(props: LonglistProps) {
    super(props);
    this.state = {};
    this.handleAppStateChanged = async () => {
      this.logic.currentTrackIndex = -1;
      await this.nextTrack();
    }
  }

  async componentDidMount() {
    await this.logic.seedIfNeeded();
    this.logic.currentTrackIndex = -1;
    await this.nextTrack();

    document.addEventListener('app:state-changed', this.handleAppStateChanged);
  }

  componentWillUnmount() {
    document.removeEventListener('app:state-changed', this.handleAppStateChanged);
  }

  handleAppStateChanged: () => Promise<void>

  async nextTrack() {
    const track = await this.logic.nextTrack();
    const stats = await this.logic.getStats();
    this.setState({currentTrack: track, stats});

    // Preload the next 5 tracks
    const nextUp = await this.logic.peekTracks(5);
    for(const next of nextUp) {
      const preview = previewURL(next);
      if (preview)
        AudioPlayer.shared.precache(preview);
    }
  }

  renderTrackDisplay() {
    if (!this.state.currentTrack) {
      return (<TrackDisplay artist="" track="" />);
    }

    const track = this.state.currentTrack;

    return <TrackDisplay
      artist={track.artist_name}
      track={track.title}
      albumArt={thumbnailURL(track)}
      playbackUrl={previewURL(track)}
      spotifyUrl={spotifyURL(track)}
      autoplay />
  }

  async updateStateAndMoveOn(newState: LongList['state']) {
    if (this.state.currentTrack) {
      await this.logic.updateState(this.state.currentTrack, newState);
    }
    await this.nextTrack();
  }

  renderStats() {
    if (this.state.stats) {
      return <p className="text-center" id="stats">
        {this.state.stats.done} / {this.state.stats.total} | {this.state.stats.accepted} in shortlist
      </p>
    }
  }

  async undoLast() {
    // This isn't a strict undo, as it doesn't undo the effect of reject-all, but it should work ok enough for now
    this.logic.currentTrackIndex--;
    let track = await this.logic.getTrack();
    if (track) {
      await this.logic.updateState(track, "unknown");
    }

    const stats = await this.logic.getStats();
    this.setState({currentTrack: track, stats});
  }

  render () {
    if (this.logic.isComplete) {
      return (<div className="Longlist">
        <p className="text-center h4">
          You're all done! Move on to editing your shortlist or picking your top 10.
        </p>
        {this.renderStats()}
      </div>);
    }
    return (
      <div className="Longlist">
        <div className="longlist-track-display">
          {this.renderTrackDisplay()}
        </div>

        <div className="button-row my-2">
            <Dropdown as={ButtonGroup}>
              <Button variant="danger" size="lg" className="svg-button flex-grow-2" onClick={() => this.updateStateAndMoveOn('reject')}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                        Nope</Button>

              <Dropdown.Toggle split variant="danger" id="dropdown-split-basic" />

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.updateStateAndMoveOn('reject-all')}>Nothing from this artist</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          <Button variant="success" size="lg" className="svg-button flex-grow-2" onClick={() => this.updateStateAndMoveOn('accept')}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
           Add to shortlist</Button>
        </div>

        <p className="text-center">Not sure? Click <strong>Add to Shortlist</strong>. You can always remove it later if you change your mind.</p>

        <p className="text-center">
          <Button variant="secondary" size="sm" className="svg-button flex-grow-2" onClick={() => this.undoLast()}>
            Undo last
          </Button>
        </p>

        {this.renderStats()}
      </div>
    );
  }
}
